import * as tyeps from './actionTypes';

let initialState = {
    //초기 값  data : {}
    isAuth : false
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case tyeps.LOGIN_USER: {

            return {
                ...state,
                isAuth: action.isAuth
            };
        }
        case tyeps.LOGOUT_USER: {
            return {
                ...state,
                isAuth: action.isAuth
            }
        }

        default:
            return state;
    }

}
