
export const _INIT_GROUP_LIST = '_INIT_GROUP_LIST';

export const _INCREAMENT_OFFSET = '_INCREAMENT_OFFSET';

export const _INIT_GROUP_DETAIL = '_INIT_GROUP_DETAIL';

export const _ADD_GROUP_BOARD = '_ADD_GROUP_BOARD';

export const _DELETE_GROUP_BOARD = '_DELETE_GROUP_BOARD';

export const _UPDATE_GROUP_BOARD = '_UPDATE_GROUP_BOARD';

export const _UPDATE_GROUP_INFO = '_UPDATE_GROUP_INFO';

export const _INIT_GROUP_INFO = '_INIT_GROUP_INFO';

export const _INIT_GROUP_CALENDAR = '_INIT_GROUP_CALENDAR';

export const _UPDATE_GROUP_CALENDAR = '_UPDATE_GROUP_CALENDAR';

export const _INIT_GROUP_HISTORY = '_INIT_GROUP_HISTORY';

export const _INIT_GROUP_MANAGEMENT = '_INIT_GROUP_MANAGEMENT';

export const _APPLY_GROUP_MANAGEMENT = '_APPLY_GROUP_MANAGEMENT';

export const _DELETE_GROUP_MANAGEMENT = '_DELETE_GROUP_MANAGEMENT';

export const _CHANGE_GROUP_HOST = '_CHANGE_GROUP_HOST';

export const _WITHDRAW_GROUP = '_WITHDRAW_GROUP';

export const _DELETE_GROUP = '_DELETE_GROUP';

export const _SIGNUP_GROUP = '_SIGNUP_GROUP';

export const _CANCEL_SIGNUP = '_CANCEL_SIGNUP';
