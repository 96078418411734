import * as types from './actionTypes';

let initialState = {
    //초기 값  data : {}
    timeLog: [],
    userLog: {}
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._INIT_PROFILE: {
            return {
                ...state,
                timeLog: action.profileData.timeLog,
                userLog: action.profileData.userLog
            };
        }


        default:
            return state;
    }

}
