import React, {useContext, useEffect, useState} from 'react';
import {Card, Button, CardBody, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const ServiceEnd = () => {

    const [modal, modalOpen] = useState(!window.localStorage.getItem('endService'));



    return (
        <Modal centered isOpen={modal} toggle={() => modalOpen(!modal)}>
            <ModalBody>
                <div className={'service_end_dialog'}>
                    <div className={'header_section'}>
                        <button className={'close_dialog_btn'} onClick={() => {
                            window.localStorage.setItem('endService', true);
                            modalOpen(false);
                        }}>
                            &times;
                        </button>
                    </div>
                    <h5>스터디투게더 서비스 종료 안내</h5>
                    <p>그동안 스터디투게더를 사랑해 주셔서 감사합니다.</p>
                    <p>스터디투게더 서비스가 종료될 예정이니<br/>
                        자세한 안내 사항을 확인해 주세요.</p>
                    <button onClick={() => {
                        window.localStorage.setItem('endService', true);
                        window.location.href = '/term/detail/38';
                    }} className={'show_more_btn'}>자세히 살펴보기
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ServiceEnd;
