import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'moment/locale/en-gb';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './assets/scss/calendar.css';
import './assets/scss/index.scss';
import Loader from "./components/common/Loader";

ReactDOM.render(
    <React.Suspense fallback={<Loader/>}>
        <Main>
            <App/>
        </Main>
    </React.Suspense>,
    document.getElementById('main')
);

