import * as types from './actionTypes';
import moment from "moment";
import jwt_decode from "jwt-decode";
import {getToken} from "../../helpers/checkAuth";
import {_INIT_INQUIRY_BOARD} from "./actionTypes";

let initialState = {
    boardList: [],
    boardInfo: {},
    replyInfo: {},
    replyNo: [186, 191]
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._INIT_INQUIRY_BOARD: {

            return {
                ...state,
                boardList: action.request.data.board,

            };
        }

        case types._GET_BOARD_INFO: {

            return {
                ...state,
                boardInfo: action.request.data.board,
                replyInfo: action.request.data.reply,
            }
        }


        default:
            return state;
    }

}
