import * as tyeps from './actionTypes';

let initialState = {
    //초기 값  data : {}
    room_list : []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case tyeps._INIT_ROOM_LIST: {

            return {
                ...state,
                room_list: action.room_list
            }
        }

        case tyeps._INCREAMENT_OFFSET: {

            return {
                ...state,
                room_list: [...state.room_list , ...action.room_list]
            }
        }


        case tyeps._SEARCH_ROOM: {
            return {
                ...state,
                room_list: action.room_list
            }
        }

        case tyeps._FILTER_ROOM: {
            return {
                ...state,
                room_list : action.room_list
            }
        }

        default:
            return state;
    }

}
