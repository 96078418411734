import moment from 'moment';
import 'moment/locale/ko'
import {badWords} from "./badWords";

moment.locale('ko');
import {lazy} from 'react'

export const retryLazy = (componentImport) =>
    lazy(async () => {
        const pageAlreadyRefreshed = JSON.parse(
            window.localStorage.getItem('pageRefreshed') || 'false'
        )
        try {
            const component = await componentImport()
            window.localStorage.setItem('pageRefreshed', 'false')
            return component
        } catch (error) {
            if (!pageAlreadyRefreshed) {
                window.localStorage.setItem('pageRefreshed', 'true')
                return window.location.reload()
            }
            throw error
        }
    })

const loadExternalApi = (callback) => {
    const existingScript = document.getElementById('loadExternalApi');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://con5.studytogether.kr/external_api.js';
        script.id = 'loadExternalApi';
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
        script.onerror = () => {
            window.location.reload(true);
        }
    }
    if (existingScript && callback) callback();
};
export default loadExternalApi;


export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1540
};

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
    JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
    parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));


//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
    if (!moment.isMoment(startDate)) throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
    if (endDate && !moment.isMoment(endDate))
        throw new Error(`End date must be a moment object, received ${typeof startDate}`);

    return `${startDate.format('ll')} - ${endDate ? endDate.format('ll') : 'Present'} • ${startDate.from(
        endDate || moment(),
        true
    )}`;
};

export const numberFormatter = (number, fixed = 2) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(number)) >= 1.0e9
        ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(number)) >= 1.0e6
            ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
            : // Three Zeroes for Thousands
            Math.abs(Number(number)) >= 1.0e3
                ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
                : Math.abs(Number(number)).toFixed(fixed);
};

export const getCurrentTimeStamp = () => {
    return moment().unix()
}

export const mobileIsApp = () => {
    return new Promise(resolve => {
        let isApp = false;
        try {
            const appRes = window.StudyTogether.isApp();
            if (appRes) isApp = true;
            resolve(isApp);
        } catch (e) {
            isApp = false;
            resolve(isApp);
        }


    })
}

export const isBadWord = (des) => {
    const description = des;
    let isInclude = false;
    badWords.forEach(word => {
        if (description.includes(word)) {
            isInclude = true
        }
    })
    return isInclude;
}


export const toHHMMSS = function (sec) {
    if (!sec || sec === 0 || sec === '0') {
        return '00:00:00'
    }
    var myNum = parseInt(sec, 10);
    var hours = Math.floor(myNum / 3600);
    var minutes = Math.floor((myNum - (hours * 3600)) / 60);
    var seconds = myNum - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}


export const secToTime = (sec) => {
    return moment.utc(sec * 1000).format('HH:mm:ss');
}

export const isSecToday = (sec) => {
    return moment(sec).isSame(moment(), 'day');
}

export const isSecAgoDay = (agoDay, sec) => {
    return moment(sec).isSame(moment().clone().subtract(agoDay, 'days').startOf('day'), 'd');
}

export const agoDay = (agoDay) => {
    return moment().subtract(agoDay, 'days').startOf('day')
}

const dayToString = (day) => {
    switch (day) {
        case 1:
            return '월'
        case 2:
            return '화'
        case 3:
            return '수'
        case 4:
            return '목'
        case 5:
            return '금'
        case 6:
            return '토'
        default:
            return '일'
    }
}

export const weekString = () => {
    moment.locale('ko');
    return [dayToString(agoDay(6).day()), dayToString(agoDay(5).day()), dayToString(agoDay(4).day()),
        dayToString(agoDay(3).day()), dayToString(agoDay(2).day()), dayToString(agoDay(1).day()), dayToString(moment().day())]
}

export const ago10Days = () => {
    moment.locale('ko');
    return [agoDay(9).format('MM/DD dd'), agoDay(8).format('MM/DD dd'), agoDay(7).format('MM/DD dd'),
        agoDay(6).format('MM/DD dd'), agoDay(5).format('MM/DD dd'), agoDay(4).format('MM/DD dd'),
        agoDay(3).format('MM/DD dd'), agoDay(2).format('MM/DD dd'), agoDay(1).format('MM/DD dd'),
        moment().format('MM/DD dd')
    ];
}

export const timeToMoment = (time) => {
    let timeString = time.split(':');
    return moment().set({hours: timeString[0], minute: timeString[1], seconds: 0})
}

export const registerTime = (mom) => {
    if (moment(mom).isSame(moment(), 'day')) {
        //오늘 날짜 인경우
        return moment(mom).format("HH:mm");
    } else {
        return moment(mom).format("MM-DD")
    }
}
//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
    let hex;
    hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
    );
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
    '#2c7be5',
    '#00d97e',
    '#e63757',
    '#39afd1',
    '#fd7e14',
    '#02a8b5',
    '#727cf5',
    '#6b5eae',
    '#ff679b',
    '#f6c343'
];

export const themeColors = {
    primary: '#2c7be5',
    secondary: '#748194',
    success: '#00d27a',
    info: '#27bcfd',
    warning: '#f5803e',
    danger: '#e63757',
    light: '#f9fafd',
    dark: '#0b1727'
};

export const grays = {
    white: '#fff',
    100: '#f9fafd',
    200: '#edf2f9',
    300: '#d8e2ef',
    400: '#b6c1d2',
    500: '#9da9bb',
    600: '#748194',
    700: '#5e6e82',
    800: '#4d5969',
    900: '#344050',
    1000: '#232e3c',
    1100: '#0b1727',
    black: '#000'
};

export const darkGrays = {
    white: '#fff',
    1100: '#f9fafd',
    1000: '#edf2f9',
    900: '#d8e2ef',
    800: '#b6c1d2',
    700: '#9da9bb',
    600: '#748194',
    500: '#5e6e82',
    400: '#4d5969',
    300: '#344050',
    200: '#232e3c',
    100: '#0b1727',
    black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
    top: pos[1] - size.contentSize[1] - 10,
    left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) => (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
    cart.reduce((accumulator, currentValue) => {
        const {id, quantity} = currentValue;
        const {price, sale} = baseItems.find(item => item.id === id);
        return accumulator + calculateSale(price, sale) * quantity;
    }, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
    const noOfPages = Math.ceil(totalSize / sizePerPage);
    const array = [];
    let pageNo = 1;
    while (pageNo <= noOfPages) {
        array.push(pageNo);
        pageNo = pageNo + 1;
    }
    return array;
};

export const capitalize = str => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const routesSlicer = ({routes, columns = 3, rows}) => {
    const routesCollection = [];
    routes.map(route => {
        if (route.children) {
            return route.children.map(item => {
                if (item.children) {
                    return routesCollection.push(...item.children);
                }
                return routesCollection.push(item);
            });
        }
        return routesCollection.push(route);
    });

    const totalRoutes = routesCollection.length;
    const calculatedRows = rows || Math.ceil(totalRoutes / columns);
    const routesChunks = [];
    for (let i = 0; i < totalRoutes; i += calculatedRows) {
        routesChunks.push(routesCollection.slice(i, i + calculatedRows));
    }
    return routesChunks;
};

export const getPageName = pageName => {
    return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
    const textField = textFieldRef.current;
    textField.focus();
    textField.select();
    document.execCommand('copy');
};

export const getDate = () => {
    const today = moment();
    return today.format('YYYY-MM-DD');
}

export const sortBy = (function () {
    var toString = Object.prototype.toString,
        // default parser function
        parse = function (x) {
            return x;
        },
        // gets the item to be sorted
        getItem = function (x) {
            var isObject = x != null && typeof x === "object";
            var isProp = isObject && this.prop in x;
            return this.parser(isProp ? x[this.prop] : x);
        };

    /**
     * Sorts an array of elements.
     *
     * @param {Array} array: the collection to sort
     * @param {Object} cfg: the configuration options
     * @property {String}   cfg.prop: property name (if it is an Array of objects)
     * @property {Boolean}  cfg.desc: determines whether the sort is descending
     * @property {Function} cfg.parser: function to parse the items to expected type
     * @return {Array}
     */
    return function sortby(array, cfg) {
        if (!(array instanceof Array && array.length)) return [];
        if (toString.call(cfg) !== "[object Object]") cfg = {};
        if (typeof cfg.parser !== "function") cfg.parser = parse;
        cfg.desc = !!cfg.desc ? -1 : 1;
        return array.sort(function (a, b) {
            a = getItem.call(cfg, a);
            b = getItem.call(cfg, b);
            return cfg.desc * (a < b ? -1 : +(a > b));
        });
    };

}());

export const checkOverlap = (timeSegments) => {
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
        timeSegment1[0].localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
        const currentEndTime = timeSegments[i][1];
        const nextStartTime = timeSegments[i + 1][0];

        if (currentEndTime > nextStartTime) {
            return true;
        }
    }

    return false;
};

export function randomHexColorItem() {
    const hexColor = ['C72627', '9853E3', '4193C0', '90CADC', 'F9BD1E', '86D8E4', 'FDAC8E', '49E744', '909495', 'FDE200', 'FF8631',
        '4D2117', 'b8ee7d', '69a8dd', '6a4be0', 'bf4ee0', 'f853a8', 'ff5fe6', 'd8ec92', 'ffb796']
    return hexColor[Math.floor(Math.random() * hexColor.length)];
}

export const maskEmail = (email) => {
    let split = email.split('@')
    return email.substr(0, 3) + new Array(split[0].length - 3).fill('x').join('') + "@" + split[1]
}

export const randomNum = () => {
    let value = ""
    for (let i = 0; i < 5; i++) {
        value += random(0, 9)
    }

    return value;
}

function random(n1, n2) {
    return parseInt(Math.random() * (n2 - n1 + 1)) + n1;
}
