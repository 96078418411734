

export const _INIT_LIST = '_INIT_LIST';

export const _INIT_ROOM_LIST = '_INIT_ROOM_LIST';

export const _INCREAMENT_OFFSET = '_INCREAMENT_OFFSET';

export const _SEARCH_ROOM = '_SEARCH_ROOM'

export const _FILTER_ROOM = '_FILTER_ROOM'

