import * as types from './actionTypes';
import moment from "moment";
import {isSecAgoDay, isSecToday} from "../../helpers/utils";

let initialState = {
    //초기 값  data : {}
    room_list: [],
    detail_info: {},
    board: [],
    room_info: {},
    room_calendar: [],
    room_management: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types._INIT_GROUP_LIST: {

            return {
                ...state,
                room_list: action.room_list
            }
        }

        case types._INCREAMENT_OFFSET: {
            return {
                ...state,
                room_list: [...state.room_list, ...action.room_list]
            }
        }

        case types._INIT_GROUP_DETAIL: {
            return {
                ...state,
                detail_info: action.detail_info,
                board: action.detail_info.data.board
            }
        }

        case types._ADD_GROUP_BOARD: {
            return {
                ...state,
                board: [action.board, ...state.board]
            }
        }

        case types._DELETE_GROUP_BOARD: {
            let board = state.board;
            const delIdx = board.findIndex(item => item.no === action.no);
            board.splice(delIdx, 1);
            return {
                ...state,
                board: [...board]
            }
        }

        case types._UPDATE_GROUP_BOARD: {
            let board = state.board;
            const updIdx = board.findIndex(item => item.no === action.board.no);
            board[updIdx] = action.board;
            return {
                ...state,
                board: [...board]
            }
        }
        case types._INIT_GROUP_INFO: {
            return {
                ...state,
                room_info: action.room
            }
        }
        case types._UPDATE_GROUP_INFO: {

            let updateRoom = action.room;
            if (updateRoom.img) {
                updateRoom.img = action.imgName;
            }

            return {
                ...state,
                room_info: {
                    ...state.room_info,
                    ...updateRoom
                }
            }
        }
        case types._INIT_GROUP_CALENDAR: {
            return {
                ...state,
                room_calendar: action.calendar
            }
        }
        case types._INIT_GROUP_HISTORY: {
            return {
                ...state,
                user_history: action.history.data.userLog,
                ranking : action.history.data.ranking
            }
        }

        case types._UPDATE_GROUP_CALENDAR: {
            let updateCalendar = [];
            let day = '';
            if (action.room.calendar.length === 0) {
                //캘린더 값 없을 떄
            } else {
                day = action.room.calendar[0].day;
            }
            let color = '#F85A59';
            action.room.calendar.forEach(item => {
                if (day !== item.day) {
                    day = item.day
                    if (color === '#F85A59') {
                        color = '#F88E59'
                    } else {
                        if (color === '#F88E59') {
                            color = '#F85A59'
                        }

                    }
                }
                let startTime = item.start_time.split(':');
                let endTime = item.end_time.split(':');

                let updateMoment = {
                    no: item.no,
                    subject: item.subject,
                    explanation: item.explanation,
                    type: item.type,
                    day: item.day,
                    ord: item.ord,
                    hex: color,
                    start_time: moment().isoWeekday(item.day).set({
                        hours: startTime[0],
                        minutes: startTime[1]
                    }).toDate(),
                    end_time: moment().isoWeekday(item.day).set({
                        hours: endTime[0],
                        minutes: endTime[1]
                    }).toDate()
                }
                updateCalendar.push(updateMoment);
            })

            return {
                ...state,
                room_calendar: updateCalendar,
                room_info: {...state.room_info, limit_start_time: action.room.limit_start_time, limit_end_time: action.room.limit_end_time}
            }
        }

        case types._INIT_GROUP_MANAGEMENT: {
            return {
                ...state,
                room_management: action.management
            }
        }

        case types._APPLY_GROUP_MANAGEMENT: {
            action.post.users.forEach(no => {
                let user = state.room_management.filter(man => man.no === no)
                user[0].status = 1;
                user[0].register_time = moment();
            })

            return {
                ...state,
                room_management: [...state.room_management]
            }
        }

        case types._DELETE_GROUP_MANAGEMENT: {
            let management = state.room_management;
            action.post.users.forEach(no => {
                const delIdx = management.findIndex(item => item.no === no);
                management.splice(delIdx, 1);
            })
            return {
                ...state,
                room_management: [...management]
            }
        }

        case types._CHANGE_GROUP_HOST: {
            return {
                ...state,
                room_info: {
                    ...state.room_info,
                    host_no: action.request.host_no
                }
            }
        }

        case types._WITHDRAW_GROUP: {
            return {
                ...state
            }
        }

        case types._DELETE_GROUP: {
            return {
                ...state
            }
        }

        case types._SIGNUP_GROUP: {
            return {
                ...state
            }
        }


        default:
            return state;
    }

}
