import {createContext} from 'react';
import {settings} from '../config';

const AppContext = createContext(settings);

export const ManagementContext = createContext({management: []});

export const newRoomContext = createContext({rooms: []});

export const CaRoomContext = createContext({});

export const MyPageContext = createContext({});

export const GroupsContext = createContext({});

export const AuthWizardContext = createContext({user: {}});

export const StudyTogetherContext = createContext({});

export const TermContext = createContext({});

export default AppContext;
