import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Layout from './layouts/Layout';
import {Provider} from 'react-redux';
import store from './store'
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import StudyTogetherProvider from "./StudyTogetherProvider";
import axios from "axios";

axios.defaults.withCredentials = true;

const App = () => {


    return (
        <Provider store={store}>
            <Router basename={process.env.PUBLIC_URL}>
                <StudyTogetherProvider>
                    <Layout/>
                </StudyTogetherProvider>
            </Router>
        </Provider>
    );
};

export default App;
