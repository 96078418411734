export const _GET_NOTIFICATION = '_GET_NOTIFICATION';


export const _INIT_NOTIFICATION = '_INIT_NOTIFICATION';

export const _DEL_NOTIFICATION = '_DEL_NOTIFICATION';

export const _INIT_READ_NOTIFICATION = '_INIT_READ_NOTIFICATION';

export const _READ_NOTIFICATION = '_READ_NOTIFICATION';
