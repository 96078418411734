import React, {useEffect, useState} from 'react';
import {StudyTogetherContext} from "./context/Context";
import { getToken, getUserInfo, resErrorCheck} from "./helpers/checkAuth";
import {useDispatch} from "react-redux";
import {initUserData} from "./components/landing/action";
import userBasic from './assets/img/base/user_basic.svg'

const StudyTogetherProvider = ({children}) => {
    const dispatch = useDispatch();

    const [profileImg, setProfileImg] = useState(userBasic);


    useEffect(() => {
        const isAuth = getToken();
        if (isAuth) {
            const user = getUserInfo();
            if (user.img) {
                setProfileImg(`https://profile.studytogether.kr/${user.img}`);
            } else {
                setProfileImg(userBasic)
            }

            initUserData().then(result => {
                if (result.data.success) {
                    dispatch(result);
                }
            }).catch(err => {
                resErrorCheck(err);
            })
        }
        //자동 로그인이 되어있는지 확인
        //const remember = localStorage.getItem('rememberLogin');

    }, [])

    const value = {
        profileImg,
        setProfileImg
    }


    return (
        <StudyTogetherContext.Provider value={value}>
            {children}
        </StudyTogetherContext.Provider>
    );
};

export default StudyTogetherProvider;
