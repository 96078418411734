import * as tyeps from './actionTypes';

let initialState = {
    //초기 값  data : {}
    recomGroup: [],
    recomRoom: [],
    markList: [],
    groupList: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case tyeps._INIT: {
            return {
                ...state,
                recomGroup: action.data.recomGroup,
                recomRoom: action.data.recomRoom
            };
        }

        case tyeps._INIT_USER_DATA: {
            return {
                ...state,
                markList: action.data.mark,
                groupList: action.data.group
            }
        }
        case tyeps._DEL_MY_GROUP: {
            let groupList = state.groupList;
            const delIdx = groupList.findIndex(item => item.no === action.no);
            groupList.splice(delIdx, 1);
            return {
                ...state,
                groupList: groupList
            }
        }
        case tyeps._ADD_MARK: {
            return {
                ...state,
                markList: [...state.markList, action.room]
            }
        }
        case tyeps._DEL_MARK: {
            let markList = state.markList;
            const delIdx = markList.findIndex(item => item.room_no === action.room_no);
            markList.splice(delIdx, 1);
            return {
                ...state,
                markList: [...markList]
            }
        }
        default:
            return state;
    }

}
