import * as types from './actionTypes';

const initialState = {
    notification_list: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_NOTIFICATION : {
            return {
                ...state,
                notification_list: action.notification_list

            }
        }

        case types._INIT_NOTIFICATION : {
            return {
                ...state,
                notification_list: []
            }
        }

        case types._DEL_NOTIFICATION : {
            const notificationList = state.notification_list.filter(notice => notice.no !== action.no);
            return {
                ...state,
                notification_list: notificationList
            }
        }

        case types._INIT_READ_NOTIFICATION : {
            state.notification_list.forEach(notice => notice.fcm_read = 1)
            return {
                ...state,
                notification_list: state.notification_list
            }
        }

        case types._READ_NOTIFICATION : {
            state.notification_list.forEach(notice => {
                if (notice.no === action.no) notice.fcm_read = 1;
            })
            return {
                ...state,
                notification_list: state.notification_list
            }
        }
        default:
            return state;
    }


}
