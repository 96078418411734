import * as types from './actionTypes'
import axios from "axios";
import {checkResponseAuth, getRefreshToken, getToken, resErrorCheck} from "../../helpers/checkAuth";
import {toast} from "react-toastify";


export const initLanding = async () => {
    const initLandingRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/landing`);
    return {
        type: types._INIT,
        data: initLandingRequest.data
    }
}

export const initUserData = async () => {
    const initUserDataRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/common/initUserData`, {
        withCredentials: true,
        headers: {
            "Content-Type": 'application/json',
            "access-token": getToken(),
            'refresh-token': getRefreshToken()
        },
    });
    return {
        type: types._INIT_USER_DATA,
        data: initUserDataRequest.data
    }


}

export const delMyGroup = (no) => {

    return {
        type: types._DEL_MY_GROUP,
        no: no
    }
}

export const addMark = async (room) => {
    const addMarkRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/room/mark/add`, {
        "room_no": room.room_no
    }, {
        headers: {
            "Content-Type": 'application/json',
            'access-token': getToken()
        }
    })
    return {
        type: types._ADD_MARK,
        room: room,
        res: addMarkRequest
    }

}
export const delMark = async (room_no) => {
    const isAuth = getToken();
    if (!isAuth) {
        history.push('/login');
        toast.error('로그인 후 이용가능한 서비스 입니다.');
    } else {
        const delMarkRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/room/mark/delete/${room_no}`, {
            headers: {
                "Content-Type": 'application/json',
                'access-token': getToken()
            }
        });
        return {
            type: types._DEL_MARK,
            room_no: room_no,
            res: delMarkRequest
        }
    }

}

