import React, {useEffect} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {CloseButton, Fade} from '../components/common/Toast';

import {RouteIf} from "../hoc/RouteIf";
import {retryLazy} from "../helpers/utils";
// import Landing from "../components/landing/Landing";
// import AuthBasicLayout from "./AuthBasicLayout";
// import WizardLayout from "./WizardLayout";
// // import VideoLayout from "./VideoLayout";
// // import GroupVideoLayout from "./GroupVideoLayout";
// import NoticeWriter from "../components/term/NoticeWriter";
// import ErrorLayout from "./ErrorLayout";
// import DashboardLayout from "./DashboardLayout";
import Loader from "../components/common/Loader";
import Inspection from "../components/errors/Inspection";
import ServiceEnd from "./ServiceEnd";

const AuthBasicLayout = retryLazy(() => import('./AuthBasicLayout'));
const WizardLayout = retryLazy(() => import('./WizardLayout'));
const Landing = retryLazy(() => import('../components/landing/Landing'));
const VideoLayout = retryLazy(() => import('./VideoLayout'));
const GroupVideoLayout = retryLazy(() => import('./GroupVideoLayout'));
const NoticeWriter = retryLazy(() => import('../components/term/NoticeWriter'));
const ErrorLayout = retryLazy(() => import('./ErrorLayout'));
const DashboardLayout = retryLazy(() => import('./DashboardLayout'));


const Layout = () => {

    // useEffect(() => {
    //     AuthBasicLayout.preload();
    //     WizardLayout.preload();
    // }, []);

    return (
        <Router fallback={<Loader/>}>
            <Switch>

                {/*점검*/}
                {/*<Route exact path="/" component={Inspection}/>*/}
                {/*<Route component={Inspection}/>*/}
                {/*점검끝*/}

                <RouteIf exact path="/" option={null} component={Landing}/>
                <RouteIf exact path="/video/:room" option={true} component={VideoLayout}/>
                <RouteIf path="/login" option={false} component={AuthBasicLayout}/>
                <RouteIf exact path="/register" option={false} component={WizardLayout}/>
                <RouteIf exact path="/groupvideo/:room" option={true} component={GroupVideoLayout}/>
                <RouteIf exact path="/writeNotice" option={true} component={NoticeWriter}/>
                <Route path="/errors" component={ErrorLayout}/>

                <Route component={DashboardLayout}/>
            </Switch>
            <ServiceEnd/>
            <ToastContainer transition={Fade} closeButton={<CloseButton/>} position={toast.POSITION.BOTTOM_LEFT}/>
        </Router>
    );
};


{/*<RouteIf exact path="/testvideo/:room" option={true} component={TestVideoLayout}/>*/}
{/*<RouteIf option={null} component={() => <Redirect to='/errors/404'/>}/>*/}

export default Layout;
