import {combineReducers} from "redux";
import board from '../components/board/reducer';
import landing from '../components/landing/reducer';
import room from '../components/room/reducer';
import group from '../components/groups/reducer';
import auth from '../components/auth/reducer';
import profile from '../components/mypage/reducer';
import notification from '../components/navbar/notification/reducer';
import inquiryBoard from '../components/inquiryBoard/reducer';

const rootReducer = combineReducers({
    room,
    group,
    landing,
    auth,
    board,
    profile,
    notification,
    inquiryBoard
});

export default rootReducer;
