import * as types from './actionTypes';
import moment from "moment";
import jwt_decode from "jwt-decode";
import {getToken} from "../../helpers/checkAuth";

let initialState = {
    board_list: [],
    board_detail: {},
    replys : [],
    searchType: '',
    kind : '',
    category : '',
    text : '',
    offset : 0,
    total_count : 0
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._INIT_BOARD: {
            return {
                ...state,
                board_list: action.board_list,
                total_count : action.total_count,
                kind : action.kind,
                searchType:  '',
                category : '',
                offset : 0,
                text : ''
            };
        }
        case types._INCREASEMENT_BOARD:{
            return {
                ...state,
                board_list : action.board_list,
                offset : action.offset
            }
        }

        case types._SEARCH_BOARD: {
            return {
                ...state,
                board_list : action.board_list,
                offset : 0,
                searchType: action.searchType,
                text : action.text,
                total_count: action.total_count,
            }
        }

        case types._CHANGE_BOARD_CATEGORY: {
            return {
                ...state,
                board_list : action.board_list,
                offset: 0,
                searchType: '',
                text: '',
                total_count: action.total_count,
                category: action.category
            }
        }

        case types._INIT_BOARD_DETAIL : {
            return {
                ...state,
                board_detail: action.board_detail,
                replys: action.replys
            }
        }


        case types._ADD_BOARD_REPLY : {

            let user = jwt_decode(getToken())

            let addData = {
                board_no: action.request.board_no,
                no: action.index,
                isMine: true,
                create_time: moment().format("YYYY-MM-DD HH:mm:ss"),
                modify_time: moment().format("YYYY-MM-DD HH:mm:ss"),
                reply_content: action.request.reply_content,
                nickname: user.nickname,
                profile_img: user.img,
                user_no: user.no
            }

            return {
                ...state,
                replys: [...state.replys, addData]
            }
        }

        case types._DELETE_BOARD_REPLY: {
            let reply = state.replys;
            const delIdx = reply.findIndex(item => item.no === action.no);
            reply.splice(delIdx, 1);

            return {
                ...state,
                replys: [...reply]
            }
        }

        case types._CREATE_BOARD : {

            return {
                ...state,

            }
        }

        case types._DELETE_BOARD: {

            return {
                ...state
            }
        }

        case types._UPDATE_BOARD: {

            let request = action.request;

            return {
                ...state,
                board_detail: {
                    ...state.board_detail,
                    category: request.category,
                    content: request.content,
                    kind: request.kind,
                    no: request.no,
                    title: request.title,
                }
            }
        }

        default:
            return state;
    }

}
