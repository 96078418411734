
export const _INIT_BOARD = '_INIT_BOARD';

export const _INIT_BOARD_DETAIL = '_INIT_BOARD_DETAIL';

export const _ADD_BOARD_REPLY = '_ADD_BOARD_REPLY';

export const _DELETE_BOARD_REPLY = '_DELETE_BOARD_REPLY';

export const _CREATE_BOARD = '_CREATE_BOARD';

export const _DELETE_BOARD = '_DELETE_BOARD';

export const _UPDATE_BOARD = '_UPDATE_BOARD';

export const _INCREASEMENT_BOARD = '_INCREASEMENT_BOARD'

export const _SEARCH_BOARD = '_SEARCH_BOARD';

export const _CHANGE_BOARD_CATEGORY = '_CHANGE_BOARD_CATEGORY'

