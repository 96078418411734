export const _INIT = '_INIT';


export const _INIT_USER_DATA = '_INIT_USER_DATA';

export const _DEL_MY_GROUP = '_DEL_MY_GROUP'

export const _ADD_MARK = '_ADD_MARK'

export const _DEL_MARK = '_DEL_MARK'
